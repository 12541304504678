import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import homeService from "api/home.service";
import { DimensionType } from "./components/SelectDimension";
import { GuidanceValue } from "./components/AdvancedSettings";
import { ICreateImageRequest } from "api/create/create.type";
import { useAppSelector } from "store";
import { isObjectEqual } from "utils";

interface ICreateContext {
  selectedDimension: DimensionType;
  onSelectDimension: (dimension: DimensionType) => void;
  onSelectGuidanceValue: (type: GuidanceValue, customValue?: any) => void;
  selectedGuidanceValue: GuidanceValue;
  formValues: ICreateImageRequest;
  onSetStylePrompt: (stylePrompt: any) => void;
  styleOptions: any;
}

export const dimensionSquare = { width: parseInt(process.env.REACT_APP_DIMENSION_SQUARE_WIDTH as string), height: parseInt(process.env.REACT_APP_DIMENSION_SQUARE_HEIGHT as string) };
export const dimensionLandscape = { width: parseInt(process.env.REACT_APP_DIMENSION_LANDSCAPE_WIDTH as string), height: parseInt(process.env.REACT_APP_DIMENSION_LANDSCAPE_HEIGHT as string) };
export const dimensionPortrait = { width: parseInt(process.env.REACT_APP_DIMENSION_PORTRAIT_WIDTH as string), height: parseInt(process.env.REACT_APP_DIMENSION_PORTRAIT_HEIGHT as string) };
export const guidanceWeak = parseInt(process.env.REACT_APP_DIMENSION_GUIDANCE_WEAK as string);
export const guidanceDefault = parseFloat(process.env.REACT_APP_DIMENSION_GUIDANCE_DEFAULT as string);
export const guidanceStrong = parseInt(process.env.REACT_APP_DIMENSION_GUIDANCE_STRONG as string);

export const CreateContext = createContext<ICreateContext>({} as any);

const CreateProvider = ({ children }: { children: ReactNode }) => {
  const { prompt, negativePrompt, guidanceValue, width, height, stylePrompt } = useAppSelector((state) => state.create);
  const handleGuidanceValue = () => {
    if (guidanceValue === guidanceWeak) return GuidanceValue.Weak;
    else if (guidanceValue === guidanceDefault) return GuidanceValue.Default;
    else return GuidanceValue.Strong;
  };

  const handleDimenstionType = () => {
    const obj = { width: width, height: height };
    if (isObjectEqual(obj, dimensionLandscape)) return DimensionType.Landscape;
    else if (isObjectEqual(obj, dimensionSquare)) return DimensionType.Square;
    else return DimensionType.Portrait;
  };

  const [selectedDimension, setSelectedDimension] = useState(handleDimenstionType());
  const [selectedGuidanceValue, setSelectedGuidanceValue] = useState(handleGuidanceValue());
  const [styleOptions, setStyleOptions] = useState<any>([]);
  const [formValues, setFormValues] = useState<ICreateImageRequest>({
    style_prompt: stylePrompt,
    prompt: prompt,
    negative_prompt: negativePrompt,
    cfg_scale: guidanceValue,
    seed: "-1", //fixed
    steps: 20, //fixed
    width: width,
    height: height,
    set_images: ["None"],
  });

  useEffect(() => {
    setFormValues({
      style_prompt: stylePrompt,
      prompt: prompt,
      negative_prompt: negativePrompt,
      cfg_scale: guidanceValue,
      seed: "-1", //fixed
      steps: 20, //fixed
      width: width,
      height: height,
      set_images: ["None"],
    });
    setSelectedDimension(handleDimenstionType());
    setSelectedGuidanceValue(handleGuidanceValue());
  }, [prompt, negativePrompt, guidanceValue, width, height, stylePrompt]);

  const onSetStylePrompt = (stylePrompt: any) => {
    setFormValues((prev) => ({ ...prev, style_prompt: stylePrompt }));
  };
  const onSelectDimension = (dimension: DimensionType) => {
    setSelectedDimension(dimension);
    let _width = 512;
    let _height = 512;
    if (dimension === DimensionType.Square) {
      _width = dimensionSquare.width;
      _height = dimensionSquare.height;
    } else if (dimension === DimensionType.Landscape) {
      _width = dimensionLandscape.width;
      _height = dimensionLandscape.height;
    } else if (dimension === DimensionType.Portrait) {
      _width = dimensionPortrait.width;
      _height = dimensionPortrait.height;
    }
    setFormValues((prev) => ({ ...prev, width: _width, height: _height }));
  };
  const onSelectGuidanceValue = (type: GuidanceValue) => {
    setSelectedGuidanceValue(type);
    let _guidanceValue = 6.5;
    if (type === GuidanceValue.Weak) _guidanceValue = guidanceWeak;
    else if (type === GuidanceValue.Default) _guidanceValue = guidanceDefault;
    else _guidanceValue = guidanceStrong;

    setFormValues((prev) => ({ ...prev, cfg_scale: _guidanceValue }));
  };

  const contextValue = { styleOptions, onSetStylePrompt, formValues, setFormValues, selectedDimension, onSelectDimension, onSelectGuidanceValue, selectedGuidanceValue };

  useEffect(() => {
    homeService.getStyleList().then((res: any) => {
      setStyleOptions(res);
      setFormValues((prev) => ({ ...prev, style_prompt: stylePrompt.prompt !== "" ? stylePrompt : res[1] }));
    });
    // homeService.getImageDetails({ gid: imagePopupItemId }).then((res) => setData(res));
    // return () => {
    //   second;
    // };
  }, []);

  return <CreateContext.Provider value={contextValue}>{children}</CreateContext.Provider>;
};

export default CreateProvider;

export const useCreate = () => useContext(CreateContext);
