import { createSlice } from "@reduxjs/toolkit";
import { dimensionPortrait, guidanceDefault } from "pages/Create/CreateContext";

export const createPageSlice = createSlice({
  name: "create",
  initialState: {
    prompt: "",
    negativePrompt: "",
    guidanceValue: guidanceDefault,
    width: dimensionPortrait.width,
    height: dimensionPortrait.height,
    stylePrompt: { prompt: "", title: "" },
  },
  reducers: {
    setCreateFormValues: (state, action) => {
      state.prompt = action.payload.prompt;
      state.negativePrompt = action.payload.negativePrompt;
      state.guidanceValue = action.payload.guidanceValue;
      state.width = action.payload.width;
      state.height = action.payload.height;
      state.stylePrompt = action.payload.stylePrompt;
    },
    resetCreateFormValues: (state) => {
      state.prompt = "";
      state.negativePrompt = "";
      state.guidanceValue = guidanceDefault;
      state.width = dimensionPortrait.width;
      state.height = dimensionPortrait.height;
      state.stylePrompt = { prompt: "", title: "" };
    },
  },
  extraReducers: {},
});

export const { setCreateFormValues, resetCreateFormValues } = createPageSlice.actions;

export default createPageSlice.reducer;
