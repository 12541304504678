import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import { showSnackbar } from "store/commonSlice";

const Snackbar = () => {
  const dispatch = useDispatch();
  const { snackbar } = useAppSelector((state) => state.common);
  const Icon = snackbar.icon;
  const snackbarRef = useRef<HTMLDivElement | null>(null);
  const [mouseEntered, setMouseEntered] = useState(false);

  useEffect(() => {
    if (snackbar.show) {
      const timer = setTimeout(() => {
        if (!mouseEntered) dispatch(showSnackbar(false));
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [snackbar.show, mouseEntered]);

  const handleMouseEnter = () => {
    setMouseEntered(true);
    if (snackbar.show) {
      dispatch(showSnackbar(true));
    }
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      dispatch(showSnackbar(false));
    }, 1250);
  };

  if (!snackbar.show) {
    return null;
  }

  return (
    <div
      ref={snackbarRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`flex items-center z-[9999] fixed top-28 left-1/2 transform -translate-x-1/2 -translate-y-1/2  gap-2.5 bg-green text-h6 text-black px-4 py-2 rounded-md ${
        snackbar.show ? "opacity-100" : "opacity-0"
      } transition-opacity duration-700`}
    >
      {Icon && <Icon className="flex-shrink-0" />}
      {snackbar.message}
    </div>
  );
};

export default Snackbar;
