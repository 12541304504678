import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "components/Input/Input";
import Button from "components/Button/Button";
import { IconRightArrow2, IconWarning } from "icons";
import userService from "api/user/user.service";

const schema = yup
  .object({
    email: yup.string().required("Please enter a valid e-mail address!").email("Please enter a valid e-mail address!"),
  })
  .required();

const ForgotPassword = ({ email, onClose, setCurrentStep }: any) => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: email || "",
    },
  });

  const onValid = (data: any) => {
    console.log(data);
    userService
      .forgotPassword({ email: data.email })
      .then(() => setIsEmailSent(true))
      .catch((e) => console.log(e));
  };
  const onHandleSubmit = () => {
    handleSubmit(onValid, (e) => console.log(e))();
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onHandleSubmit();
    }
  };

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col items-center gap-2.5">
        <div className="flex w-full justify-between items-center">
          <IconRightArrow2 className="cursor-pointer rotate-180 text-grey" onClick={() => setCurrentStep(0)} />
          <h2 className="text-h2">Forgot Password</h2>
          <IconRightArrow2 className=" opacity-0 rotate-180 text-grey" />
        </div>
        <h4 className="text-bodyMd">No worries, we`ll send you reset instructions</h4>
      </div>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2.5">
          <Input onKeyDown={handleKeyPress} error={errors.email?.message} {...register("email")} placeholder="Enter your email" containerClassName="w-full"></Input>
          {isEmailSent && (
            <span className="flex text-green text-h6 items-center gap-[5px]">
              <IconWarning className="h-[17px] w-[17px]" />
              Email sent! Please follow the reset instructions.
            </span>
          )}
          <div className="flex flex-col gap-2.5">
            {!isEmailSent && (
              <Button className="btn-primary w-full" onClick={onHandleSubmit}>
                Reset Password
              </Button>
            )}
            {isEmailSent && (
              <Button className="btn-secondary w-full" onClick={onClose}>
                Close
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
