import Button from "components/Button/Button";
import Input from "components/Input/Input";
import Modal from "components/Modal/Modal";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import { setUser, toggleEditProfileModal } from "store/commonSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import userService from "api/user/user.service";

const schema = yup
  .object({
    email: yup.string().required("Please enter a valid e-mail address!").email("Please enter a valid e-mail address!"),
    name: yup.string().required("Please enter your name!"),
    username: yup.string().required("Please enter a username!"),
    work: yup.string(),
  })
  .required();

const EditProfileModal = () => {
  const dispatch = useDispatch();
  const { showEditProfileModal, user } = useAppSelector((state) => state.common);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onValid = (data: any) => {
    userService
      .editProfile(data, user.id)
      .then(() => {
        userService
          .getUserInfo({ uid: user?.id })
          .then((res: any) => dispatch(setUser({ id: res.user_info.id, email: res.user_info.email, name: res.user_info.name, username: res.user_info.username, work: res.user_info.work })));
        onClose(true);
      })
      .catch((e) => console.log(e));
  };

  const onHandleSubmit = () => {
    handleSubmit(onValid, (e) => console.log(e))();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onHandleSubmit();
    }
  };

  function onClose(noReset?: any) {
    if (!noReset)
      reset({
        email: user?.email || "",
        name: user?.name || "",
        work: user?.work || "",
      });
    dispatch(toggleEditProfileModal());
  }

  return (
    <Modal onClose={onClose} show={showEditProfileModal} bodyClassName="w-[29%]">
      <div className="flex flex-col w-full items-center p-5">
        <h3 className="text-h3 text-white pb-5">Edit Profile</h3>
        <div className="flex flex-col w-full gap-5">
          <div className="flex flex-col gap-2">
            <Input onKeyDown={handleKeyPress} defaultValue={user?.email} error={errors.email?.message} {...register("email")}></Input>
            <Input onKeyDown={handleKeyPress} defaultValue={user?.name} error={errors.name?.message} {...register("name")}></Input>
            <Input onKeyDown={handleKeyPress} defaultValue={user?.username} error={errors.username?.message} {...register("username")}></Input>
            <Input onKeyDown={handleKeyPress} defaultValue={user?.work} error={errors.work?.message} {...register("work")}></Input>
          </div>
          <div className="flex flex-col gap-2.5">
            <Button className="btn-primary" onClick={onHandleSubmit}>
              Save
            </Button>
            <Button className="btn-secondary" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditProfileModal;
