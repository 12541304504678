import paymentService from "api/payment/payment.service";

export const clipboardCopy = (text: string) => {
  try {
    navigator.clipboard.writeText(text);
  } catch {
    window.navigator["clipboard"].writeText(text);
  }
};

export const downloadImages = async (imageURLs: string[]) => {
  AnalyticsEvent("design_downloaded", "", "click");

  const link = document.createElement("a");
  link.href = imageURLs[0];
  link.target = "_blank";
  link.download = "welcome_image.png";

  // Append the link to the body to trigger the download
  document.body.appendChild(link);

  // Trigger a click event on the link
  link.click();

  // Remove the link after a short delay
  await new Promise((resolve) => setTimeout(resolve, 2000));

  document.body.removeChild(link);
};

export function isObjectEqual(obj1: any, obj2: any): boolean {
  // If the types are different, they are not equal
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  // If they are primitive values, compare them using strict equality
  if (typeof obj1 !== "object" || obj1 === null || obj2 === null) {
    return obj1 === obj2;
  }

  // Compare the properties of the objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!isObjectEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export const isObjectEmpty = (object: any) => Object.keys(object).length === 0;

export const formatDate = (date: string) => {
  if (date === undefined || date === null || date === "") {
    return "";
  }
  const _date = new Date(date);

  const options = { year: "numeric", month: "long", day: "numeric" } as const;
  const formattedDate = _date.toLocaleDateString("en-US", options);

  return formattedDate;
};

export function parsePromptKeyword(input: string) {
  // Remove parentheses if present
  input = input.replace(/^\(|\)$/g, "");
  const [prompt, weight] = input.split(":").map((part) => part.trim());

  return { prompt, weight };
}

export function addWeightToPromptKeyword(input: string) {
  return { prompt: input, weight: "1" };
}

export function hasWeight(input: string): boolean {
  return /^\(.*\)$/.test(input);
}

export function arrayToString(arr: string[]): string {
  console.log(arr);
  console.log(arr.map((item: any) => `(${item.prompt}: ${item.weight})`).join(", "));

  return arr.map((item: any) => `(${item.prompt}: ${item.weight})`).join(", ");
}

import ReactGA from "react-ga4";

export function AnalyticsEvent(event_name: string, category: string, action: string) {
  ReactGA.event(event_name, {
    category: category,
    action: action,
  });
}

export async function handleManageSubscription() {
  try {
    const response = await paymentService.manageSubscription();
    window.location.href = response?.portal_url;
  } catch (error) {
    console.log(error);
  }
}
