import Modal from "components/Modal";
import { IconClose } from "icons";
import { ImageLmvhVideoPoster } from "images";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import { toggleAnnouncementModal } from "store/commonSlice";

const AnnouncementModal = () => {
  const dispatch = useDispatch();
  const { showAnnouncementModal } = useAppSelector((state) => state.common);

  function handleOnClose() {
    dispatch(toggleAnnouncementModal());
  }

  return (
    <Modal onClose={handleOnClose} show={showAnnouncementModal} bodyClassName="max-w-[480px]">
      <IconClose className="absolute top-2 left-2 cursor-pointer flex-shrink-0 w-6 h-6 text-grey" onClick={handleOnClose} />

      <div className="flex flex-col p-5 gap-5">
        <span className="text-h7-satoshi text-center">Refabric is joining the LVMH Startup Acceleration Program at La Maison des Startups</span>
        <div className="flex w-full rounded-xl bg-orange">
          <video
            poster={ImageLmvhVideoPoster}
            className="rounded-xl"
            autoPlay
            loop
            // muted
            playsInline
            src="
        https://s3.amazonaws.com/cdn.refabric.com/static/refabric_lvmh.mp4
          "
          />
        </div>
      </div>
    </Modal>
  );
};

export default AnnouncementModal;
