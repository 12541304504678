import { RefabricProURL } from "../index";
import { ApiResponse } from "../HttpClient";
import { IUtilsImageRemoveBackgroundRequest, IUtilsImageDescribeRequest, IUtilsUploadPatternRequest, IUtilsContactUsRequest } from "./utils.type";

export default {
  async removeBackground(data: IUtilsImageRemoveBackgroundRequest, options?: any): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`utils/remove_bg`, data, options);
  },
  async describeImage(data: IUtilsImageDescribeRequest, options?: any): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`utils/describe`, data, options);
  },
  async uploadPattern(data: IUtilsUploadPatternRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`utils/upload_pattern`, data, {});
  },
  async getUploadedPatterns(): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`utils/upload_pattern`, {});
  },
  async contactUs(data: IUtilsContactUsRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`utils/contacts`, data, {});
  },
  async getMagicSelectMasks(image_path: string, options?: any): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`utils/magic-selection-points`, { image_path: image_path }, options);
  },
  async getBlogs(): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`statics/blogs`, {});
  },
};
