import { MintgramURL } from "../index";
import { ApiResponse } from "../HttpClient";

export default {
  async describeImage(data: any): Promise<ApiResponse<any>> {
    return await MintgramURL.post(`v1/describe`, data, {});
  },
  async describeStatus(created_id: any): Promise<ApiResponse<any>> {
    return await MintgramURL.get(`v1/describe_status/${created_id}`, {});
  },
  async finetunePrompt(data: any): Promise<ApiResponse<any>> {
    return await MintgramURL.post(`v1/fine_tune_prompt`, data, {});
  },
};
