import React from "react";
import ErrorModal from "./ErrorModal";
import EditProfileModal from "components/EditProfileModal/EditProfileModal";
import ChangePasswordModal from "components/ChangePasswordModal/ChangePasswordModal";
import DeleteAccountModal from "components/DeleteAccountModal/DeleteAccountModal";
import LogoutModal from "components/LogoutModal/LogoutModal";
import UserFlow from "components/UserFlow/UserFlow";
import Snackbar from "components/Snackbar/Snackbar";
import LeaveFeedback from "components/LeaveFeedback/LeaveFeedback";
import { useIsMobile } from "hooks/useIsMobile";
import PromoBanner from "../../components/PromoBanner";
import { useDispatch } from "react-redux";
import { setShowUserFlow } from "store/commonSlice";
import LandingFooter from "pages/Home/components/LandingFooter/LandingFooter";
import Header from "./Header";

interface Props {
  children: React.ReactNode;

  [key: string]: any;
}

const shouldRenderPromo = false;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Layout = ({ children, ...etc }: Props) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (etc?.needLogin) dispatch(setShowUserFlow(true));
  }, []);

  return (
    <div className="bg-white min-h-screen flex flex-col">
      {!etc.hiddenHeader && <Header />}
      <main className="flex-1 flex flex-col relative">
        {!isMobile && shouldRenderPromo && <PromoBanner />}
        <EditProfileModal />
        <ChangePasswordModal />
        <DeleteAccountModal />
        <LogoutModal />
        <UserFlow />
        <Snackbar />
        <LeaveFeedback />
        {children}
        {/* {isMobile && <MobileMenuBar />} */}
        <ErrorModal />
      </main>
      {!etc.hiddenFooter && <LandingFooter />}
    </div>
  );
};

export default Layout;
