import React from "react";
import { IconChevron } from "icons";
import clsx from "clsx";
import { useClickOutside } from "../../hooks/useClickOutside";

export interface ISelectOption {
  prompt?: any;
  title: any;
  id?: any;
}

interface ISelect {
  options: ISelectOption[];
  value?: ISelectOption;
  onChange: (value: ISelectOption) => void;
  defaultId?: number;
  direction?: string;
  className?: string;
}

const Select = ({ options, value, defaultId, onChange, direction = "bottom", className }: ISelect) => {
  const [show, setShow] = React.useState(false);
  const listRef = React.useRef<HTMLUListElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const isDirectionTop = direction === "top";
  const [currentValue, setCurrentValue] = React.useState<any>(value);

  useClickOutside(containerRef, () => {
    setShow(false);
  });

  const onToggle = () => {
    setShow(!show);
  };
  const onSelect = (option: ISelectOption) => {
    onChange(option);
    setCurrentValue(option);
    onToggle();
  };

  return (
    <div className={clsx("relative")} ref={containerRef}>
      <div className={clsx("flex-center py-3 px-2.5 gap-2.5 text-black border border-white border-opacity-10 rounded-lg cursor-pointer", className)} onClick={onToggle}>
        <span className="text-bodyMd text-overflow">{currentValue?.title ?? "Please Select"}</span>
        <IconChevron className={clsx("transition-all duration-300 w-5 h-5 text-black")} />
      </div>
      <div
        className={clsx(
          "absolute z-10 transition-all duration-300 text-black min-w-full overflow-hidden",
          isDirectionTop ? "-translate-y-full" : "translate-y-full",
          show ? (isDirectionTop ? "-top-2" : "-bottom-2") : isDirectionTop ? "top-0" : "bottom-0"
        )}
        style={{ height: show ? `${(listRef.current?.scrollHeight as number) + 2}px` : 0 }}
      >
        <ul className="border border-black border-opacity-10 bg-white rounded-lg divide-y-[1px] divide-black divide-opacity-10" ref={listRef}>
          {options.map((option: any, index) => {
            return (
              <li
                key={index}
                className="text-h6 py-3 px-2.5 hover:bg-black hover:bg-opacity-10 cursor-pointer divide divide-y divide-black first:rounded-t-lg last:rounded-b-lg"
                onClick={() => onSelect(option)}
              >
                {option.title}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Select;
