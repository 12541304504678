import React from "react";
import { IconInstagram, IconLinkedin, RefabricLogoBig } from "icons";
import { PATHS } from "router/config/paths";
import { BLOG_URL, INSTAGRAM_URL, LINKEDIN_URL } from "utils/constants";
import ContactUsModal from "components/ContactUsModal";
import { useNavigate } from "react-router-dom";
import { AnalyticsEvent } from "utils";

const LandingFooter = () => {
  const navigate = useNavigate();
  const [showContactUsModal, setShowContactUsModal] = React.useState(false);

  return (
    <footer className="flex flex-col pb-10 p-[30px] lg:p-10 bg-green">
      {showContactUsModal && <ContactUsModal show={showContactUsModal} onClose={() => setShowContactUsModal(false)} type="contactus" />}

      <div className="flex flex-col lg:flex-row gap-10 lg:gap-0 justify-between pb-10 lg:pb-[60px] border-b">
        <RefabricLogoBig
          className="cursor-pointer !w-full !h-[60px] lg:!w-auto lg:!h-auto"
          onClick={() => {
            AnalyticsEvent("footer_refabric_logo", "", "click");
            navigate(PATHS.HOME + window.location.search);
          }}
        />
        <div className="flex flex-wrap gap-y-8 gap-x-[120px] lg:gap-[120px] pr-10">
          <div className="flex flex-col gap-5">
            <span className="text-headline-satoshi">menu</span>
            <div className="flex flex-col gap-2">
              <a
                className="text-bodyMd"
                href={PATHS.FEATURES + window.location.search}
                onClick={() => {
                  AnalyticsEvent("footer_menu_features", "", "click");
                }}
              >
                Features
              </a>
              <a
                className="text-bodyMd "
                href={PATHS.SHOWCASE + window.location.search}
                onClick={() => {
                  AnalyticsEvent("footer_menu_showcase", "", "click");
                }}
              >
                Showcase
              </a>
              <a
                className="text-bodyMd "
                href={PATHS.PRICING + window.location.search}
                onClick={() => {
                  AnalyticsEvent("footer_menu_pricing", "", "click");
                }}
              >
                Pricing
              </a>
              <a
                className="text-bodyMd "
                href={PATHS.AMBASSADORS + window.location.search}
                onClick={() => {
                  AnalyticsEvent("footer_menu_ambassadors", "", "click");
                }}
              >
                Ambassadors
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <span className="text-headline-satoshi">company</span>
            <div className="flex flex-col gap-2">
              <a
                className="text-bodyMd"
                href={PATHS.ABOUT + window.location.search}
                onClick={() => {
                  AnalyticsEvent("footer_company_about", "", "click");
                }}
              >
                About
              </a>
              <a
                className="cursor-pointer text-bodyMd"
                onClick={() => {
                  setShowContactUsModal(true);
                  AnalyticsEvent("footer_company_contactus", "", "click");
                }}
              >
                Contact Us
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <span className="text-headline-satoshi">Resources</span>
            <div className="flex flex-col gap-2">
              <a
                href={BLOG_URL + window.location.search}
                target="blank"
                rel="noopener noreferrer"
                className="text-bodyMd"
                onClick={() => {
                  AnalyticsEvent("footer_resources_blog", "", "click");
                }}
              >
                Blog
              </a>
              <a
                className="text-bodyMd"
                href={`${PATHS.PRICING}` + window.location.search}
                onClick={() => {
                  AnalyticsEvent("footer_resources_faq", "", "click");
                }}
              >
                FAQ
              </a>
              <a
                className="text-bodyMd "
                href={PATHS.PRIVACY_POLICY + window.location.search}
                onClick={() => {
                  AnalyticsEvent("footer_resources_privacy", "", "click");
                }}
              >
                Privacy Policy
              </a>
              <a
                className="text-bodyMd "
                href={PATHS.TERMS_OF_USE + window.location.search}
                onClick={() => {
                  AnalyticsEvent("footer_resources_terms", "", "click");
                }}
              >
                Terms of Use
              </a>
              {/* <a className="text-bodyMd ">Case Studies</a> */}
              {/* <a className="text-bodyMd ">Tutorials</a> */}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-5 lg:flex-row lg:justify-between pt-5">
        <div className="flex items-center gap-4">
          <span>
            <b>USA:</b> 651 N Broad St, Suite 201, Middletown, DE 19709
          </span>
          <span>|</span>
          <span>
            <b>France:</b> 5 Rue Eugène Freyssinet, 75013 Paris
          </span>
        </div>
        <div className="flex items-center text-bodyMd gap-2.5">
          <a
            href={INSTAGRAM_URL + window.location.search}
            target="blank"
            rel="noopener noreferrer"
            onClick={() => {
              AnalyticsEvent("footer_social_instagram", "", "click");
            }}
          >
            <IconInstagram className="w-8 h-8" />
          </a>
          <a
            href={LINKEDIN_URL + window.location.search}
            target="blank"
            rel="noopener noreferrer"
            onClick={() => {
              AnalyticsEvent("footer_social_linkedin", "", "click");
            }}
          >
            <IconLinkedin className="w-8 h-8" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default LandingFooter;
