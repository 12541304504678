import { yupResolver } from "@hookform/resolvers/yup";
import userService from "api/user/user.service";
import clsx from "clsx";
import Button from "components/Button/Button";
import Modal from "components/Modal/Modal";
import Textarea from "components/Textarea/Textarea";
import { useClickOutside } from "hooks/useClickOutside";
import { IconChevron, IconSettings, IconWarning } from "icons";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import { setLeaveFeedbackSubject, setShowLeaveFeedback } from "store/commonSlice";
import * as yup from "yup";

export enum SubjectType {
  SUPPORT = "Support",
  FEEDBACK = "Feedback",
  ENTERPRISE_SOLUTION = "Enterprise Solution",
  PARTNERSHIP = "Partnership",
}

const schema = yup
  .object({
    message: yup.string().required("Please enter your feedback message!"),
  })
  .required();

const LeaveFeedback = () => {
  const dispatch = useDispatch();
  const { showLeaveFeedback, user, leaveFeedbackSelectedSubject } = useAppSelector((state) => state.common);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [showSubjectError, setShowSubjectError] = useState(false);
  const [showFeedbackSuccess, setShowFeedbackSuccess] = useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showLeaveFeedback) {
      setSelectedSubject(leaveFeedbackSelectedSubject);
    }
  }, [showLeaveFeedback]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  useClickOutside(dropdownRef, () => {
    setShowDropdown(false);
  });

  function onClose() {
    if (showFeedbackSuccess) {
      reset({
        message: "",
      });
      setSelectedSubject("");
    }
    setSelectedSubject("");
    dispatch(setLeaveFeedbackSubject(""));
    setShowSubjectError(false);
    dispatch(setShowLeaveFeedback(false));
    setShowFeedbackSuccess(false);
    clearErrors();
  }

  const onValid = (data: any) => {
    if (selectedSubject === "") {
      setShowSubjectError(true);

      return;
    }
    const _message = data.message;
    userService
      .sendFeedback({ name_surname: user.name, email: user.email, subject: selectedSubject, message: _message })
      .then(() => {
        setShowFeedbackSuccess(true);
      })
      .catch((e) => console.log(e));
  };

  const onHandleSubmit = () => {
    handleSubmit(onValid, () => {
      if (selectedSubject === "") setShowSubjectError(true);
      else setShowSubjectError(false);
    })();
  };

  function renderDropdownSelections() {
    const selections = [SubjectType.SUPPORT, SubjectType.FEEDBACK, SubjectType.ENTERPRISE_SOLUTION, SubjectType.PARTNERSHIP];

    return selections.map((item, idx) => (
      <ul
        key={`ul${idx}`}
        className={clsx("cursor-pointer mx-2.5 px-2.5 text-h6 py-3 hover:bg-bg-2 hover:text-white rounded-[15px]", selectedSubject === item ? "bg-white text-bg" : "")}
        onClick={() => {
          setSelectedSubject(item);
          setShowDropdown(false);
        }}
      >
        {item}
      </ul>
    ));
  }

  return (
    <Modal onClose={onClose} show={showLeaveFeedback} bodyClassName={"w-[400px] max-w-[90vw] overflow-x-hidden"}>
      {showFeedbackSuccess ? (
        <div className="flex flex-col p-5 gap-5">
          <div className="flex flex-col gap-2.5 items-center">
            <h3 className="text-h3 text-white text-center">Thank You for Your Feedback!</h3>
            <span className="text-bodyMd text-grey text-center">We appreciate your insights! Our team will be in touch to follow up on your feedback. </span>
          </div>
          <Button className="btn-primary" onClick={onClose}>
            Close
          </Button>
        </div>
      ) : (
        <div className="flex flex-col p-5 gap-5">
          <div className="flex flex-col gap-2.5 items-center">
            <h3 className="text-h3 text-white text-center">Leave a Feedback</h3>
            <span className="text-bodyMd text-grey text-center">
              We greatly appreciate your feedback on our product, and please don`t hesitate to reach out if you require any assistance or support.
            </span>
          </div>
          <div className="flex flex-col gap-2.5">
            <div className="relative flex flex-col">
              <div
                className={clsx("cursor-pointer flex w-full justify-between px-2.5 py-3 rounded-2xl border border-white border-opacity-10", showDropdown ? "pointer-events-none" : "")}
                onClick={() => setShowDropdown((prev) => !prev)}
              >
                <h6 className={clsx(selectedSubject !== "" ? "text-white" : "text-grey")}>{selectedSubject !== "" ? selectedSubject : "Select a subject"}</h6>
                <IconChevron className="w-5 h-5 text-white" />
              </div>
              {showSubjectError && (
                <span className="flex text-orange text-h6 items-center gap-[5px]">
                  <IconWarning className="h-[17px] w-[17px]" />
                  Please select a subject!
                </span>
              )}
              {showDropdown && (
                <div ref={dropdownRef} className="absolute flex flex-col gap-2.5 pb-2.5 top-[55px] text-white w-full bg-bg rounded-2xl border border-white border-opacity-10">
                  <div className="flex gap-2.5 bg-bg-2 text-h6 w-full rounded-t-[15px] p-3">
                    <IconSettings className="w-5 h-5" />
                    Select a subject
                  </div>
                  {renderDropdownSelections()}
                </div>
              )}
            </div>
            <Textarea {...register("message")} error={errors.message?.message} className="bg-bg h-[180px] !text-h6" containerClassName="!bg-bg h-[200px]" placeholder="Write your message" />
          </div>
          <div className="flex flex-col gap-2.5">
            <Button className="btn-primary" onClick={onHandleSubmit}>
              Send
            </Button>
            <Button className="btn-secondary" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default LeaveFeedback;
