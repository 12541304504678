import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

const UseScrollTop = () => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if (pathname.includes("posts") || pathname.includes("explore")) return;
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default UseScrollTop;
