import { createSlice } from "@reduxjs/toolkit";
import { Model } from "components/Searchbar/Searchbar";
import { useLocalStorage } from "hooks/useLocalStorage";
import { IconDownload } from "icons";
import { EventDispatchLogout } from "router/Router";
import { DEFAULT_MODEL_ID } from "utils/constants";

export const commonSlice = createSlice({
  name: "common",
  initialState: {
    showSearchBar: true,
    showImagePopup: false,
    imagePopupItemId: null,
    imagePopupImageIdx: null as any,
    showEditProfileModal: false,
    showChangePasswordModal: false,
    showDeleteAccountModal: false,
    showCreatePrivacyModal: false,
    showCreateFastGenerationModal: false,
    showCreateFeaturesModal: false,
    showCreateOutOfCreditModal: false,
    showLogoutModal: false,
    showUserFlow: false,
    isAuthorized: false,
    user: {
      id: "" as any,
      name: "",
      username: "",
      email: "",
      work: "",
      privateSession: 0,
      advInfo: {
        type: "",
        state: "",
        info: "",
      },
    },
    isCheckingToken: true,
    selectedModel: (useLocalStorage().getItem("selectedModel") ?? { id: DEFAULT_MODEL_ID }) as Model,
    selectedSort: { title: "Most Popular", id: "most_popular" } as any,
    searchInput: { text: "", image: "" },
    noReset: false,
    snackbar: {
      show: false,
      message: null as JSX.Element | null,
      icon: IconDownload,
    },
    showPromptAssistant: false,
    showLeaveFeedback: false,
    isPromptAssistantFullyClosed: false,
    leaveFeedbackSelectedSubject: "",
    searchKeys: [],
    showAnnouncementModal: false,
  },
  reducers: {
    toggleSearchBar: (state, action) => {
      state.showSearchBar = action.payload;
    },
    setShowSearchBar: (state, action) => {
      state.showSearchBar = action.payload;
    },
    toggleImagePopup: (state) => {
      state.showImagePopup = !state.showImagePopup;
    },
    setImagePopupItemId: (state, action) => {
      state.imagePopupItemId = action.payload;
    },
    setImagePopupImageIdx: (state, action) => {
      state.imagePopupImageIdx = action.payload;
    },
    toggleEditProfileModal: (state) => {
      state.showEditProfileModal = !state.showEditProfileModal;
    },
    toggleChangePasswordModal: (state) => {
      state.showChangePasswordModal = !state.showChangePasswordModal;
    },
    toggleDeleteAccountModal: (state) => {
      state.showDeleteAccountModal = !state.showDeleteAccountModal;
    },
    toggleCreatePrivacyModal: (state) => {
      state.showCreatePrivacyModal = !state.showCreatePrivacyModal;
    },
    toggleCreateFastGenerationModal: (state) => {
      state.showCreateFastGenerationModal = !state.showCreateFastGenerationModal;
    },
    toggleCreateFeaturesModal: (state) => {
      state.showCreateFeaturesModal = !state.showCreateFeaturesModal;
    },
    toggleCreateOutOfCreditModal: (state) => {
      state.showCreateOutOfCreditModal = !state.showCreateOutOfCreditModal;
    },
    toggleLogoutModal: (state) => {
      state.showLogoutModal = !state.showLogoutModal;
    },
    toggleUserFlow: (state) => {
      state.showUserFlow = !state.showUserFlow;
    },
    setShowUserFlow: (state, action) => {
      state.showUserFlow = action.payload;
    },
    setIsAuthorized: (state, action) => {
      state.isAuthorized = action.payload;
    },
    setUser: (state, action) => {
      state.user = {
        id: action.payload.id,
        name: action.payload.name,
        username: action.payload.username,
        email: action.payload.email,
        work: action.payload.work,
        privateSession: action.payload.privateSession,
        advInfo: action.payload.advInfo,
      };
    },
    signOut: () => {
      window.dispatchEvent(new CustomEvent(EventDispatchLogout));
    },
    setCheckingToken: (state, action) => {
      state.isCheckingToken = action.payload;
    },
    setSelectedModel: (state, action) => {
      state.selectedModel = action.payload;
    },
    setSelectedSort: (state, action) => {
      state.selectedSort = action.payload;
    },
    setSearchInput: (state, action) => {
      state.searchInput.text = action.payload.text;
      state.searchInput.image = action.payload.image;
    },
    setNoReset: (state, action) => {
      state.noReset = action.payload;
    },
    setSnackbar: (state, action) => {
      state.snackbar.show = action.payload?.show;
      state.snackbar.message = action.payload?.message;
      state.snackbar.icon = action.payload?.icon;
    },
    showSnackbar: (state, action) => {
      state.snackbar.show = action.payload;
    },
    setShowPromptAssistant: (state, action) => {
      state.showPromptAssistant = action.payload;
    },
    setShowLeaveFeedback: (state, action) => {
      state.showLeaveFeedback = action.payload;
    },
    setLeaveFeedbackSubject: (state, action) => {
      state.leaveFeedbackSelectedSubject = action.payload;
    },
    setIsPromptAssistantFullyClosed: (state, action) => {
      state.isPromptAssistantFullyClosed = action.payload;
    },
    setSearchKeys: (state, action) => {
      state.searchKeys = action.payload;
    },
    toggleAnnouncementModal: (state) => {
      state.showAnnouncementModal = !state.showAnnouncementModal;
    },
  },
  extraReducers: {},
});

export const {
  toggleSearchBar,
  toggleImagePopup,
  setImagePopupItemId,
  setImagePopupImageIdx,
  toggleEditProfileModal,
  toggleChangePasswordModal,
  toggleDeleteAccountModal,
  toggleCreatePrivacyModal,
  toggleCreateFastGenerationModal,
  toggleLogoutModal,
  toggleUserFlow,
  setIsAuthorized,
  setUser,
  setShowUserFlow,
  signOut,
  setCheckingToken,
  setSelectedModel,
  setSearchInput,
  setShowSearchBar,
  setNoReset,
  setSnackbar,
  showSnackbar,
  setShowPromptAssistant,
  setShowLeaveFeedback,
  setIsPromptAssistantFullyClosed,
  setLeaveFeedbackSubject,
  setSelectedSort,
  setSearchKeys,
  toggleCreateFeaturesModal,
  toggleCreateOutOfCreditModal,
  toggleAnnouncementModal,
} = commonSlice.actions;

export default commonSlice.reducer;
