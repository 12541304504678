import React from "react";
import Button from "components/Button/Button";
import Modal from "components/Modal/Modal";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import { setIsAuthorized, toggleLogoutModal } from "store/commonSlice";
import { EventDispatchLogout } from "router/Router";

const LogoutModal = () => {
  const dispatch = useDispatch();
  const { showLogoutModal } = useAppSelector((state) => state.common);

  function onClose() {
    dispatch(toggleLogoutModal());
  }

  function handleLogout() {
    window.dispatchEvent(new CustomEvent(EventDispatchLogout));
  }

  return (
    <Modal onClose={onClose} show={showLogoutModal} bodyClassName="w-[29%]">
      <div className="flex flex-col w-full items-center p-5">
        <div className="flex flex-col items-center gap-2.5 px-2.5 pb-5">
          <h3 className="text-h3 text-white">Logout</h3>
          <h6 className="text-bodyMd text-center text-grey max-w-[340px]">Are you sure you would like to log out of your Refabric account?</h6>
        </div>
        <div className="flex flex-col w-full gap-2.5">
          <Button className="btn-primary" onClick={handleLogout}>
            Logout
          </Button>
          <Button className="btn-secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;
