import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "components/Input/Input";
import Button from "components/Button/Button";
import userService from "api/user/user.service";
import { setIsAuthorized, setUser } from "store/commonSlice";
import { useDispatch } from "react-redux";
import { IconEyeOff, IconEyeOn, IconWarning } from "icons";
import { MVPrefabricURL, MintgramURL } from "api";
import { AnalyticsEvent } from "utils";
import { useLocalStorage } from "hooks/useLocalStorage";
import { v4 as uuidv4 } from "uuid";
import { CLIENT_ID_LOCAL_STORAGE_KEY } from "utils/constants";
import ReactGA from "react-ga4";
import GoogleButton from "components/GoogleButton";

const schema = yup
  .object({
    email: yup.string().required("Please enter a valid e-mail address!").email("Please enter a valid e-mail address!"),
    password: yup.string().required("Please enter a password!"),
  })
  .required();

const SignIn = ({ email, onClose, setCurrentStep }: any) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: email || "",
      password: "",
    },
  });

  const onValid = (data: any) => {
    const client_id = uuidv4();
    userService
      .login({ email: data.email, password: data.password, client_id: client_id })
      .then((res: any) => {
        AnalyticsEvent("log_in", "", "click");
        dispatch(setUser({ id: res.id }));
        ReactGA.set({ userId: res.id });
        useLocalStorage().setItem(CLIENT_ID_LOCAL_STORAGE_KEY, client_id);
        MVPrefabricURL.setAuthToken(res.token);
        MintgramURL.setAuthToken(res.token);
        dispatch(setIsAuthorized(true));
        onClose();
      })
      .catch((e) => {
        if (!e.return_status) {
          setPasswordError(true);
          setValue("password", "");
        }
      });
  };

  const onHandleSubmit = () => {
    handleSubmit(onValid, (e) => console.log(e))();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onHandleSubmit();
    }
  };

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col items-center gap-2.5">
        <h2 className="text-h2">Welcome Back</h2>
        <span className="text-bodyMd">
          Don’t have an account?{" "}
          <u className="cursor-pointer" onClick={() => setCurrentStep(1)}>
            Sign up
          </u>
        </span>
      </div>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2.5">
          <Input onKeyDown={handleKeyPress} error={errors.email?.message} {...register("email")} placeholder="Enter your email" containerClassName="w-full"></Input>
          <Input
            onKeyDown={handleKeyPress}
            type={showPassword ? "text" : "password"}
            error={errors.password?.message}
            {...register("password")}
            placeholder="Password"
            containerClassName="w-full h-[44.2px]"
            icon={
              !showPassword ? (
                <IconEyeOn className="flex-shrink-0 text-grey w-5 h-5 cursor-pointer" onClick={() => setShowPassword(true)} />
              ) : (
                <IconEyeOff className="flex-shrink-0 text-grey w-5 h-5 cursor-pointer" onClick={() => setShowPassword(false)} />
              )
            }
          ></Input>
          {passwordError && (
            <span className="flex text-orange text-h6 items-center gap-[5px]">
              <IconWarning className="h-[17px] w-[17px] flex-shrink-0" />
              Invalid password. Please try again.
            </span>
          )}
          <div className="flex">
            <span className="cursor-pointer text-right text-[15px] leading-[140%] tracking-[-0.225px] text-bg underline" onClick={() => setCurrentStep(2)}>
              Forgot Password
            </span>
          </div>
          <Button className="btn-primary w-full" onClick={onHandleSubmit}>
            Log in
          </Button>
        </div>
        <div className="flex items-center gap-2.5">
          <div className="h-[1px] bg-grey-light w-full" /> <span className="text-bodyMd">or</span>
          <div className="h-[1px] bg-grey-light w-full" />
        </div>
        <GoogleButton text="Log in with Google" afterComplete={() => onClose()} />
      </div>
    </div>
  );
};

export default SignIn;
