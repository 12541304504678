import { yupResolver } from "@hookform/resolvers/yup";
import userService from "api/user/user.service";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import Modal from "components/Modal/Modal";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import { toggleChangePasswordModal } from "store/commonSlice";
import * as yup from "yup";

const schema = yup
  .object({
    currentPassword: yup.string().required("Please enter your current password!"),
    newPassword: yup.string().required("Please enter your current password!"),
    confirmNewPassword: yup
      .string()
      .required("Please confirm your new password!")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.newPassword === value;
      }),
  })
  .required();

const ChangePasswordModal = () => {
  const dispatch = useDispatch();
  const { showChangePasswordModal, user } = useAppSelector((state) => state.common);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onValid = (data: any) => {
    const _newPassword = data.confirmNewPassword;
    userService.changePassword({ password: _newPassword }, user.id).then(() => {
      dispatch(toggleChangePasswordModal());
    });
  };
  const onHandleSubmit = () => {
    handleSubmit(onValid, (e) => console.log(e))();
  };

  function onClose() {
    reset({
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    });
    dispatch(toggleChangePasswordModal());
  }

  return (
    <Modal onClose={onClose} show={showChangePasswordModal} bodyClassName="w-[29%]">
      <div className="flex flex-col w-full items-center p-5">
        <h3 className="text-h3 text-white pb-5">Change Password</h3>
        <div className="flex flex-col w-full gap-5">
          <div className="flex flex-col gap-2">
            <Input type="password" placeholder="Curent Password" error={errors.currentPassword?.message} {...register("currentPassword")} />
            <Input type="password" placeholder="Set New Password" error={errors.newPassword?.message} {...register("newPassword")} />
            <Input type="password" placeholder="Confirm New Password" error={errors.confirmNewPassword?.message} {...register("confirmNewPassword")} />
          </div>
          <div className="flex flex-col gap-2.5">
            <Button className="btn-primary" onClick={onHandleSubmit}>
              Change Password
            </Button>
            <Button className="btn-secondary" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
