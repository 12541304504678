import { MintgramURL } from "../index";
import { ApiResponse } from "../HttpClient";
import { IChangePasswordRequest, ICreatedHistoryRequest, IEditProfileRequest, IGoogleLogin, IUserInfoRequest, IUserLoginRequest, IUserRegisterRequest } from "./user.type";
import { IHomePageImagesResponse } from "api/home.type";
import { AnalyticsEvent } from "utils";
import axios from "axios";
import useAuthToken from "hooks/useAuthToken";

export default {
  async checkIsRegistered(data: any): Promise<ApiResponse<any>> {
    return await MintgramURL.post("v1/is_register", data, {});
  },
  async register(data: IUserRegisterRequest): Promise<ApiResponse<any>> {
    return await MintgramURL.post("v1/register", data, {});
  },
  async login(data: IUserLoginRequest): Promise<ApiResponse<any>> {
    return await MintgramURL.post("v1/login", data, {});
  },
  async getUserInfo(params: IUserInfoRequest): Promise<ApiResponse<any>> {
    return await MintgramURL.get("v1/user_detail", { params });
  },
  async getHistory(params: any): Promise<ApiResponse<IHomePageImagesResponse>> {
    return await MintgramURL.get("v1/action_list", { params });
  },
  async tokenControl(data: { client_id: string }, config: any): Promise<ApiResponse<any>> {
    return await MintgramURL.post("v1/token_control", data, config);
  },
  async getCreatedHistory(params: ICreatedHistoryRequest): Promise<ApiResponse<any>> {
    return await MintgramURL.get("v1/user_created_history", { params });
  },
  async editProfile(data: IEditProfileRequest, id: any): Promise<ApiResponse<any>> {
    return await MintgramURL.put(`v1/user_detail/${id}`, data, {});
  },
  async changePassword(data: IChangePasswordRequest, id: any): Promise<ApiResponse<any>> {
    return await MintgramURL.put(`v1/change_password/${id}`, data, {});
  },
  async deleteAccount(id: any): Promise<ApiResponse<any>> {
    return await MintgramURL.get(`v1/delete_account/${id}`, {});
  },
  async googleLogin(data: IGoogleLogin): Promise<ApiResponse<any>> {
    return await MintgramURL.post("v1/google_login", data, {});
  },
  async like(data: any): Promise<ApiResponse<any>> {
    AnalyticsEvent("design_liked", "", "click");

    return await MintgramURL.post("v1/like", data, {});
  },
  async unlike(data: any): Promise<ApiResponse<any>> {
    AnalyticsEvent("design_unliked", "", "click");

    return await MintgramURL.post("v1/unlike", data, {});
  },
  async getFavorites(params: any): Promise<ApiResponse<any>> {
    return await MintgramURL.get("v1/favorite_list", { params });
  },
  async setPrivateSession(data: any): Promise<ApiResponse<any>> {
    const { is_private_posts } = data;
    if (is_private_posts === 1) AnalyticsEvent("private_session_on", "", "click");
    else AnalyticsEvent("private_session_off", "", "click");

    return await MintgramURL.put("v1/user/private_post", data, {});
  },
  async forgotPassword(data: any): Promise<ApiResponse<any>> {
    return await MintgramURL.post("v1/forgot_password", data, {});
  },
  async resetPassword(token: string, data: any): Promise<ApiResponse<any>> {
    return await MintgramURL.post(`v1/reset_password/${token}`, data, {});
  },
  async sendFeedback(data: any): Promise<ApiResponse<any>> {
    return await MintgramURL.post("v1/contact_form", data, {});
  },
  async verifyEmail(token: string, data: any): Promise<ApiResponse<any>> {
    return await MintgramURL.post(`v1/verify_email/${token}`, data, {});
  },
  async downloadHighResolution(path: string): Promise<any> {
    return await axios.post(
      path,
      {},
      {
        headers: {
          Authorization: useAuthToken.getAuthTokenFromLocalStorage(),
        },
      }
    );
  },
  async downloadStandardResolution(path: string): Promise<any> {
    return await axios.get(path, {
      headers: {
        Authorization: useAuthToken.getAuthTokenFromLocalStorage(),
      },
    });
  },
};
