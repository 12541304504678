export enum PATHS {
  HOME = "/",
  FEATURES = "/features",
  SHOWCASE = "/showcase",
  PRICING = "/pricing",
  AMBASSADORS = "/ambassadors",
  ABOUT = "/about",
  PRIVACY_POLICY = "/privacy-policy",
  TERMS_OF_USE = "/terms-of-use",

  NEW_PASSWORD = "/reset-password/:token",
  ACCOUNT = "/account",
  LOGIN = "/login",
  CREATE = "/create",
  EMAIL_VERIFY = "/email-verify/:token",
}
