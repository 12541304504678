export const IMAGE_SIZE_LIMIT = 5;
export const DEFAULT_MODEL_ID = 6;
export const CLIENT_ID_LOCAL_STORAGE_KEY = "refabric_basic_client_id";
export const APP_URL = process.env.REACT_APP_APP_URL as string;
export const BASIC_APP_CREATE_URL = APP_URL + "/create";
export const BASIC_APP_EXPLORE_URL = APP_URL + "/";
export const BLOG_URL = "//blog.refabric.com/ai-fashion-blog";
export const INSTAGRAM_URL = "//instagram.com/refabric_ai";
export const LINKEDIN_URL = "//www.linkedin.com/company/refabricai/";
export const DISCORD_URL = "//discord.gg/Gd8Tn8vJ";
