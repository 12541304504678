import React from "react";
import Button from "components/Button";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { PATHS } from "router/config/paths";

const TryForFree = ({ containerClassName, btnClassName, callback }: any) => {
  const navigate = useNavigate();

  return (
    <a
      onClick={() => {
        navigate(PATHS.PRICING + window.location.search);
        if (callback) callback();
      }}
      className={clsx("flex w-fit", containerClassName)}
    >
      <Button className={clsx(btnClassName, "whitespace-nowrap")}>Try for Free</Button>
    </a>
  );
};

export default TryForFree;
