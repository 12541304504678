import { IconLandscape, IconPortrait, IconSquare } from "icons";
import React from "react";
import { useCreate } from "../CreateContext";
import clsx from "clsx";
import { AnalyticsEvent } from "utils";

export enum DimensionType {
  Landscape = "Landscape",
  Portrait = "Portrait",
  Square = "Square",
  None = "None",
}

const SelectDimension = () => {
  const { selectedDimension, onSelectDimension } = useCreate();

  const Box = ({ children, type, ...etc }: any) => (
    <div
      className={clsx(
        "flex-center flex h-[45px] lg:h-[35px] lg:py-0 w-full border lg:rounded-xl rounded-md gap-[15px] lg:gap-[5px] border-white border-opacity-10 text-grey hover:text-white cursor-pointer",
        selectedDimension === type ? "bg-white bg-opacity-10 cursor-default text-white" : ""
      )}
      {...etc}
    >
      {children}
    </div>
  );

  return (
    <div className="flex gap-3">
      <Box
        type={DimensionType.Landscape}
        onClick={() => {
          AnalyticsEvent("dimension_landscape_selected", "", "click");
          onSelectDimension(DimensionType.Landscape);
        }}
      >
        <IconLandscape className="w-6 h-6 lg:w-4 lg:h-4" />
        <h6 className="text-head6 lg:text-head7">16:9</h6>
      </Box>
      <Box
        type={DimensionType.Square}
        onClick={() => {
          AnalyticsEvent("dimension_square_selected", "", "click");
          onSelectDimension(DimensionType.Square);
        }}
      >
        <IconSquare className="w-6 h-6 lg:w-4 lg:h-4" />
        <h6 className="text-head6 lg:text-head7">16:16</h6>
      </Box>
      <Box
        type={DimensionType.Portrait}
        onClick={() => {
          AnalyticsEvent("dimension_portrait_selected", "", "click");
          onSelectDimension(DimensionType.Portrait);
        }}
      >
        <IconPortrait className="w-6 h-6 lg:w-4 lg:h-4" />
        <h6 className="text-head6 lg:text-head7">9:16</h6>
      </Box>
    </div>
  );
};

export default SelectDimension;
