import React, { useState } from "react";
import Modal from "components/Modal/Modal";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";
import { useAppSelector } from "store";
import { useDispatch } from "react-redux";
import { toggleUserFlow } from "store/commonSlice";

const UserFlow = () => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [email, setEmail] = useState("");
  const { showUserFlow } = useAppSelector((state) => state.common);
  function onClose() {
    dispatch(toggleUserFlow());
    setCurrentStep(0);
  }

  function renderStep() {
    switch (currentStep) {
      case 0:
        return <SignIn email={email} onClose={onClose} setCurrentStep={setCurrentStep} />;
      case 1:
        return <SignUp email={email} onClose={onClose} setCurrentStep={setCurrentStep} />;
      case 2:
        return <ForgotPassword email={email} onClose={onClose} setCurrentStep={setCurrentStep} />;
      default:
        break;
    }
  }

  return (
    <Modal onClose={onClose} show={showUserFlow} bodyClassName=" w-full max-w-[480px]">
      <div className="flex flex-col p-5">{renderStep()}</div>
    </Modal>
  );
};

export default UserFlow;
