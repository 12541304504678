import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import { RouteConfig, ROUTES } from "./config";
import { clearAuthTokenFromLocalStorage, getAuthTokenFromLocalStorage, setAuthTokenFromLocalStorage } from "hooks/useAuthToken";
import userService from "api/user/user.service";
import { useDispatch } from "react-redux";
import { setCheckingToken, setIsAuthorized, setUser } from "store/commonSlice";
import { useLocalStorage } from "hooks/useLocalStorage";
import PromptAssistant from "components/PromptAssistant/PromptAssistant";
import { useIsMobile } from "hooks/useIsMobile";
import UseScrollTop from "hooks/UseScrollTop";
import { CLIENT_ID_LOCAL_STORAGE_KEY } from "utils/constants";
import ReactGA from "react-ga4";
import { MVPrefabricURL, MintgramURL } from "api";

const NotFound = React.lazy(() => import("../pages/NotFound"));

const getRoute = (route: RouteConfig) => {
  const isMobile = useIsMobile();
  const Component = route.mobileComponent && isMobile ? route.mobileComponent : route.component;
  const Layout = route.layout ?? React.Fragment;

  return (
    <Route
      key={route.path}
      path={route.path}
      element={
        <ErrorBoundary>
          <Layout {...route.layoutProps} needLogin={route.needLogin}>
            <React.Suspense fallback={null}>
              <Component />
              {!isMobile && (
                <div className="fixed z-50 bottom-10 right-10">
                  <PromptAssistant
                    handleClose={() => {
                      return;
                    }}
                  />
                </div>
              )}
            </React.Suspense>
          </Layout>
        </ErrorBoundary>
      }
    >
      {route?.children?.map((child) => getRoute(child))}
    </Route>
  );
};

export const EventDispatchLogout = "RefabricDispatchLogout";

const Router = () => {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const client_id = params.get("client_id");

  React.useEffect(() => {
    let headers = {};
    if (token !== null) {
      setAuthTokenFromLocalStorage(token);
      MVPrefabricURL.setAuthToken(token);
      MintgramURL.setAuthToken(token);
      headers = { Authorization: `${token}` };
    }
    if (client_id !== null) useLocalStorage().setItem(CLIENT_ID_LOCAL_STORAGE_KEY, client_id);

    if (getAuthTokenFromLocalStorage() !== null)
      userService
        .tokenControl({ client_id: useLocalStorage().getItem(CLIENT_ID_LOCAL_STORAGE_KEY) ?? client_id }, { headers })
        .then((res: any) => {
          if (res.status) {
            dispatch(setIsAuthorized(true));
            dispatch(setCheckingToken(false));

            dispatch(setUser({ id: res?.user_data.id, username: res?.user_data.username, email: res?.user_data.email }));
            ReactGA.set({ userId: res.id });
          } else {
            dispatch(setIsAuthorized(false));
            dispatch(setCheckingToken(false));

            window.dispatchEvent(new CustomEvent(EventDispatchLogout));
          }
        })
        .catch((e) => {
          console.log(e);
          dispatch(setIsAuthorized(false));
          dispatch(setCheckingToken(false));
          window.dispatchEvent(new CustomEvent(EventDispatchLogout));
        });
    else dispatch(setCheckingToken(false));
  }, []);

  React.useEffect(() => {
    const detectPermissionDenied = () => {
      useLocalStorage().removeItem("selectedModel");
      useLocalStorage().removeItem(CLIENT_ID_LOCAL_STORAGE_KEY);
      clearAuthTokenFromLocalStorage();
      window.location.href = "/";
    };
    window.addEventListener(EventDispatchLogout, detectPermissionDenied);

    return () => {
      window.removeEventListener(EventDispatchLogout, detectPermissionDenied);
    };
  }, []);

  return (
    <BrowserRouter>
      <UseScrollTop />
      <Routes>
        {ROUTES.map((route) => getRoute(route))}

        <Route
          path="*"
          element={
            <ErrorBoundary>
              <React.Suspense fallback={null}>
                <NotFound />
              </React.Suspense>
            </ErrorBoundary>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
