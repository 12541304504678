import React, { useRef, useState } from "react";
import AWS from "aws-sdk";
import { IconAddImage, IconCopy, IconRefabricLogo, IconWarning } from "icons";
import clsx from "clsx";
import chatgptService from "api/chatgpt/chatgpt.service";
import { AnalyticsEvent, clipboardCopy } from "utils";
import { IMAGE_SIZE_LIMIT } from "utils/constants";

const Message = ({
  from,
  message,
  imageUpload,
  setMessages,
  setShowUserChoices,
  copyItems,
  messages,
  src,
  setIsBotWorking,
  copyPrompt,
  error,
  setShowRefreshButton,
  uid,
}: {
  from: number;
  message: any;
  imageUpload?: boolean;
  setShowUserChoices: any;
  setMessages: any;
  messages: any[];
  src: string;
  setIsBotWorking: any;
  copyItems: any;
  copyPrompt: any;
  error: any;
  setShowRefreshButton: any;
  uid: any;
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [imageValue, setImageValue] = useState("");

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isUploadTypeError, setIsUploadTypeError] = useState(false);
  const [isUploadSizeError, setIsUploadSizeError] = useState(false);

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });
  const s3 = new AWS.S3();

  function recursiveDescribeImageStatus(created_id: any) {
    chatgptService
      .describeStatus(created_id)
      .then((res: any) => {
        console.log(res);
        if (res.description !== "" && res.description !== undefined) {
          setMessages((prev: any) => [
            ...prev,
            { from: 0, message: "Here are your results:", imageUpload: false },
            {
              from: 0,
              message: res.description,
              imageUpload: false,
            },
          ]);
          setMessages((prev: any) => [...prev, { copyItems: [res.description] }]);
          setShowUserChoices(true);
          setIsBotWorking(false);

          return;
        }
        setTimeout(() => recursiveDescribeImageStatus(created_id), 3000);
      })
      .catch(() => {
        setIsBotWorking(false);
        setMessages((prev: any) => [...prev, { from: 0, error: true, message: "Something went wrong. Please refresh the page!", imageUpload: false, element: null }]);
        setShowRefreshButton(true);
      });
  }

  const handleImageUpload = async (event: any, isDropped = false) => {
    event.preventDefault(); //needed for drag and drop

    let file;
    if (isDropped) {
      if (event.dataTransfer.files.length > 1) {
        console.error("Upload only one image!");

        return;
      }

      file = event.dataTransfer.files[0];
    } else file = event.target.files?.[0];

    if (file.size > IMAGE_SIZE_LIMIT * 1000000) {
      setIsUploadSizeError(true);

      return;
    }

    if (file) {
      setIsUploading(true);
      const params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME as string,
        Key: "style_temp/" + file.name,
        Body: file,
        ACL: "public-read",
      };

      try {
        const res = await s3.upload(params).promise();
        setIsUploading(false);
        setImageValue(res.Location);

        const updatedMessages = messages.map((message, index) => (index === messages.length - 1 ? { ...message, src: res.Location } : message));
        setMessages(updatedMessages);
        setIsBotWorking(true);
        chatgptService
          .describeImage({ set_images: res.Location, uid: uid })
          .then((res: any) => {
            recursiveDescribeImageStatus(res.created_id);
          })
          .catch(() => {
            setIsBotWorking(false);
            setMessages((prev: any) => [...prev, { from: 0, error: true, message: "Something went wrong. Please refresh the page!", imageUpload: false, element: null }]);
            setShowRefreshButton(true);
          });
      } catch (error) {
        setIsBotWorking(false);
        setMessages((prev: any) => [...prev, { from: 0, error: true, message: "Something went wrong. Please refresh the page!", imageUpload: false, element: null }]);
        setShowRefreshButton(true);
      }
    }
  };

  const CopyItems = ({ idx, prompt }: { idx: number; prompt: string }) => {
    const [isCopied, setIsCopied] = useState(false);
    function handleCopy() {
      setIsCopied(true);
      clipboardCopy(prompt);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }

    return (
      <div className="flex-center cursor-pointer h-[38px] bg-white rounded-md gap-[6px] text-black text-h7 px-2.5" onClick={handleCopy}>
        <IconCopy className="w-[18px] h-[18px]" />
        {isCopied ? <span>Copied!</span> : idx}
      </div>
    );
  };

  const CopyPrompt = ({ prompt }: { prompt: string }) => {
    const [isCopied, setIsCopied] = useState(false);
    function handleCopy() {
      AnalyticsEvent("prompt_assistant_copy", "", "click");
      setIsCopied(true);
      clipboardCopy(prompt);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }

    return (
      <div className="flex-center cursor-pointer h-[38px] bg-white rounded-md gap-[6px] text-black text-h7 px-2.5" onClick={handleCopy}>
        <IconCopy className="w-[18px] h-[18px]" />
        {isCopied ? <span>Copied!</span> : <span>Copy Prompt</span>}
      </div>
    );
  };

  return (
    <div className={clsx("flex  gap-2.5", from === 0 ? "" : "justify-end", typeof message !== "string" ? "items-end" : "")}>
      {from === 0 && (
        <div className="flex-center  w-10 h-10 p-[5px] bg-bg rounded-full border border-border">
          <IconRefabricLogo className="w-8 h-8" />
        </div>
      )}
      <div className="flex flex-col gap-[15px]">
        {copyPrompt ? (
          <CopyPrompt prompt={copyPrompt} />
        ) : copyItems?.length > 0 ? (
          <div className="flex self-end gap-[5px]">
            {copyItems.map((item: any, idx: number) => (
              <CopyItems key={`copyItem${idx}`} idx={idx + 1} prompt={item} />
            ))}
          </div>
        ) : typeof message === "string" ? (
          message !== "" ? (
            <div
              className={clsx(
                "flex text-bodySm p-2.5 rounded-md max-w-[400px] ",
                from === 0 ? "text-white gap-1.5 bg-white bg-opacity-[15%] border border-white border-opacity-[15%]" : "text-black bg-white border border-white",
                error ? "!text-orange !border-orange" : ""
              )}
            >
              {error && <IconWarning className="w-5 h-5 text-orange" />}
              {message}
            </div>
          ) : (
            <></>
          )
        ) : (
          message.map((item: any, idx: any) => (
            <div
              key={`msgitem-${idx}`}
              className={clsx(
                "flex text-bodySm  p-2.5 rounded-md max-w-[400px] ",
                from === 0 ? "text-white bg-white bg-opacity-[15%] border border-white border-opacity-[15%]" : "text-black bg-white border border-white"
              )}
            >
              <div className="flex gap-2.5 text-bodySm">
                <div className="flex-center w-[18px] h-[18px] flex-shrink-0 bg-white rounded-[4px] text-black">{idx + 1}</div>
                <span className="text-bodySm text-white">{item}</span>
              </div>
            </div>
          ))
        )}

        {imageUpload && (
          <div
            onDrop={(e) => {
              e.preventDefault();
              if (!["image/png", "image/jpg", "image/jpeg"].includes(e?.dataTransfer?.files[0]?.type)) {
                setIsUploadTypeError(true);

                return;
              } else setIsUploadTypeError(false);

              if (isUploading || imageValue || (src !== undefined && src !== "")) return;
              handleImageUpload(e, true);
            }}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            className={clsx(
              "flex-col w-40 h-40 cursor-pointer flex-center rounded-lg px-2.5 py-4 gap-2.5 border border-white border-opacity-10",
              isUploadTypeError ? "border-orange border-opacity-100" : ""
            )}
            onClick={handleIconClick}
          >
            <input
              ref={fileInputRef}
              disabled={isUploading || imageValue || (src !== undefined && src !== "") ? true : false}
              accept="image/png, image/jpg, image/jpeg"
              style={{ display: "none" }}
              type="file"
              onChange={handleImageUpload}
            ></input>

            {isUploading ? (
              <div className="h-2.5 w-3/4 bg-white bg-opacity-10 rounded-full overflow-hidden">
                <div className="h-full bg-green w-1/2 animate-image-upload" />
              </div>
            ) : src || imageValue ? (
              <div className="relative w-40 h-40 group">
                <img src={src !== "" ? src : imageValue.toString()} className="w-40 h-40 object-cover rounded-lg" />
              </div>
            ) : isUploadTypeError ? (
              <>
                <IconWarning className="text-orange w-8 h-8" />
                <h5 className="text-bodySm text-orange text-center"> File type is not supported. Please upload .jpeg, .jpg or .png</h5>
              </>
            ) : isUploadSizeError ? (
              <>
                <IconWarning className="text-orange w-8 h-8" />
                <h5 className="text-bodySm text-orange text-center"> File size must be less than {IMAGE_SIZE_LIMIT}MB</h5>
              </>
            ) : (
              <>
                <IconAddImage className="text-grey w-8 h-8" />
                <h5 className="text-bodySm text-grey line-clamp-2 text-center">Upload your image</h5>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Message;
