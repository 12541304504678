import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import Button from "components/Button/Button";
import { IconAddImage, IconClose, IconEyeLock, IconGenerate, IconRefabricLogo, IconRefresh, IconSend, IconUnlock } from "icons";
import Input from "components/Input/Input";
import Message from "./components/Message";
import chatgptService from "api/chatgpt/chatgpt.service";
import { useAppSelector } from "store";
import { useDispatch } from "react-redux";
import { setIsPromptAssistantFullyClosed, setShowPromptAssistant } from "store/commonSlice";
import { useIsMobile } from "hooks/useIsMobile";
import { AnalyticsEvent } from "utils";
import { useNavigate } from "react-router-dom";
import { PATHS } from "router/config/paths";

const initialMessage = [{ from: 0, message: "Hi there 👋 Do you need help on writing your prompt?", imageUpload: false, element: null }];

const PromptAssistant = ({ handleClose }: { handleClose: () => void }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showPromptAssistant, user } = useAppSelector((state) => state.common);
  const [showChatBox, setShowChatBox] = useState(false);
  const [showUserChoices, setShowUserChoices] = useState(true);
  const [showInputField, setShowInputField] = useState(false);
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  const [messages, setMessages] = useState<any>([{ from: 0, message: "Hi there 👋 Do you need help on writing your prompt?", imageUpload: false, element: null }]);
  const [isBotWorking, setIsBotWorking] = useState(false);
  const chatContainer = useRef<HTMLInputElement>(null);
  const inputPrompt = useRef<HTMLInputElement>(null);
  const [isChatBoxAlreadyOpened, setIsChatBoxAlreadyOpened] = useState(false);
  const [showOnlyBubble, setShowOnlyBubble] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!showChatBox || isChatBoxAlreadyOpened) return;
    if (showChatBox) setIsChatBoxAlreadyOpened(true);
  }, [showChatBox]);

  function scrollBottom() {
    if (chatContainer.current) {
      chatContainer.current.scrollTop = chatContainer.current.scrollHeight;
    }
  }
  useEffect(() => {
    if (!showPromptAssistant) setShowChatBox(false);
  }, [showPromptAssistant]);
  useEffect(() => {
    scrollBottom();
  }, [messages, showUserChoices, isBotWorking, showRefreshButton]);

  useEffect(() => {
    scrollBottom();
  }, [showChatBox]);

  const UserChoices = () => (
    <div className="flex flex-col gap-2.5 self-end">
      {/* <Button
        className="btn-secondary w-[260px] justify-start text-h7 btn-sm !bg-green"
        onClick={() => {
          AnalyticsEvent("prompt_assistant_upgrade_to_unlock", "", "click");
          navigate(PATHS.PRICING);
        }}
      >
        <IconUnlock className="w-5 h-5" />
        Upgrade to Unlock
      </Button> */}
      <Button
        className="btn-secondary w-[260px] justify-start text-h7 btn-sm !bg-green"
        onClick={() => {
          AnalyticsEvent("prompt_assistant_describe", "", "click");
          setMessages((prev: any) => [
            ...prev,
            { from: 1, message: "Create a prompt with my image", imageUpload: false },
            { from: 0, message: "If you could upload a photo similar to the output you want I can create a prompt for you!", imageUpload: false },
            { from: 0, message: "", imageUpload: true },
          ]);
          setShowUserChoices(false);
        }}
      >
        <IconAddImage className="w-5 h-5" />
        Describe my image as a prompt
      </Button>
      <Button
        className="btn-secondary w-[260px] justify-start text-h7 btn-sm !bg-orange"
        onClick={() => {
          AnalyticsEvent("prompt_assistant_beautify", "", "click");
          setMessages((prev: any) => [
            ...prev,
            { from: 1, message: "Help me beautify my prompt", imageUpload: false },
            { from: 0, message: "Write the prompt you want me to beautify", imageUpload: false },
          ]);
          setShowUserChoices(false);
          setShowInputField(true);
          inputPrompt.current?.focus();
        }}
      >
        <IconGenerate className="w-5 h-5" />
        Help me beautify my prompt
      </Button>
      {/* <Button className="btn-secondary w-[260px] justify-start text-h7 btn-sm !bg-pink">
        <IconAnalyze className="w-5 h-5" />
        Analyze my prompt
      </Button> */}
    </div>
  );

  const RefrehshButton = () => (
    <div className="flex self-end">
      <Button
        className="btn-primary"
        onClick={() => {
          setMessages(initialMessage);
          setShowUserChoices(true);
          setShowInputField(false);
          setShowRefreshButton(false);
          setIsBotWorking(false);
        }}
      >
        <IconRefresh className="w-5 h-5" />
        Refresh the Page
      </Button>
    </div>
  );

  function handleInput() {
    setShowUserChoices(false);
    if (inputPrompt.current) {
      const _prompt = inputPrompt.current.value;
      setMessages((prev: any) => [...prev, { from: 1, message: _prompt, imageUpload: false }]);
      setIsBotWorking(true);
      setShowInputField(false);
      chatgptService
        .finetunePrompt({ prompt: _prompt })
        .then((res) => {
          setMessages((prev: any) => [...prev, { from: 0, message: res, imageUpload: false }, { copyPrompt: res }]);
          setIsBotWorking(false);
          setShowUserChoices(true);
        })
        .catch(() => {
          setIsBotWorking(false);
          setMessages((prev: any) => [...prev, { from: 0, error: true, message: "Something went wrong. Please refresh the page!", imageUpload: false, element: null }]);
          setShowRefreshButton(true);
        });
    }
  }
  function wrapHandleClose() {
    if (showChatBox) {
      AnalyticsEvent("prompt_assistant_chatbox_close", "", "click");
      setShowChatBox(false);
    } else {
      AnalyticsEvent("prompt_assistant_close", "", "click");

      if (isMobile) dispatch(setShowPromptAssistant(false));
      setShowOnlyBubble(true);
      handleClose();
    }
  }

  return (
    <div className={clsx(showPromptAssistant ? "flex" : "hidden", "gap-2", showChatBox ? "flex-col items-end" : "flex-row")}>
      {showChatBox && (
        <div className="flex lg:w-full lg:top-0 lg:z-[1000] lg:h-[100dvh] lg:fixed lg:rounded-none flex-col gap-[15px] h-[85vh] bg-bg-2 w-[560px] rounded-xl border border-border">
          <div className="flex justify-between items-center text-bodySm text-black w-full h-fit bg-white rounded-t-xl lg:rounded-none px-2.5 py-2">
            <span className="">Prompt Assistant</span>
            <div className="cursor-pointer flex-center bg-black bg-opacity-10 w-7 h-7 rounded-full" onClick={wrapHandleClose}>
              <IconClose className="w-3 h-3" />
            </div>
          </div>
          <div className="flex flex-col h-full overflow-y-scroll gap-[15px] px-[15px] pb-[15px] scroll-smooth" ref={chatContainer}>
            {messages.map((item: any, idx: number) => (
              <Message
                key={`msg${idx}`}
                messages={messages}
                src={item?.src}
                from={item.from}
                message={item.message}
                copyItems={item?.copyItems}
                copyPrompt={item?.copyPrompt}
                imageUpload={item.imageUpload}
                setMessages={setMessages}
                setShowUserChoices={setShowUserChoices}
                setIsBotWorking={setIsBotWorking}
                setShowRefreshButton={setShowRefreshButton}
                error={item?.error}
                uid={user.id}
              />
            ))}
            {isBotWorking && (
              <div className="flex items-center gap-2.5">
                <div className="flex-center  w-10 h-10 p-[5px] bg-bg rounded-full border border-border">
                  <IconRefabricLogo className="w-8 h-8" />
                </div>
                <div className="flex-center w-8 h-2">
                  <div className="dot-flashing"></div>
                </div>
              </div>
            )}
            {showUserChoices && <UserChoices />}
            {showRefreshButton && <RefrehshButton />}
          </div>
          {showInputField && (
            <div className="flex items-center text-white w-full lg:rounded-b-none rounded-b-[15px] ">
              <Input
                type="text"
                ref={inputPrompt}
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    handleInput();
                  }
                }}
                placeholder="Write your prompt here..."
                className="bg text-bodySm"
                containerClassName="w-full py-2.5 pl-[15px] pr-2 !bg-border border-none rounded-t-none lg:rounded-b-none rounded-b-[15px]"
                icon={
                  <div className="cursor-pointer flex-shrink-0 flex-center h-9 w-9 rounded-xl bg-green" onClick={handleInput}>
                    <IconSend className="w-[22px] h-[22px] text-black" />
                  </div>
                }
              />
            </div>
          )}
        </div>
      )}
      {!useIsMobile() ? (
        <>
          {!showOnlyBubble && (
            <Button
              className="icon-btn w-10 p-2.5 flex-center bg-bg rounded-xl border border-border"
              onClick={() => {
                wrapHandleClose();
                dispatch(setIsPromptAssistantFullyClosed(true));
              }}
            >
              <IconClose className="text-white w-3 h-3" />
            </Button>
          )}
          {!showOnlyBubble && (
            <div className="flex cursor-default text-bodyMd w-[260px] text-white p-2.5 border border-border bg-bg-2 rounded-[14px]">Hi there 👋 Do you need help on writing your prompt?</div>
          )}
          <div
            className="flex-center cursor-pointer w-12 h-12 p-[5px] bg-bg rounded-full border border-border"
            onClick={() => {
              AnalyticsEvent("prompt_assistant_opened", "", "click");
              setShowOnlyBubble(true);
              setShowChatBox(true);
            }}
          >
            <IconRefabricLogo className="w-10 h-10" />
          </div>
        </>
      ) : (
        <div className="flex w-full p-2.5 gap-2.5 bg-bg-2 border border-t-0 border-bg-3">
          <div
            className="flex-center cursor-pointer w-8 h-8 p-[5px] bg-bg rounded-full border border-border"
            onClick={() => {
              AnalyticsEvent("prompt_assistant_opened", "", "click");
              setShowChatBox(true);
            }}
          >
            <IconRefabricLogo className="w-10 h-10" />
          </div>
          <div className="flex cursor-pointer text-bodyMd w-full text-white bg-bg-2 " onClick={() => setShowChatBox(true)}>
            Hi there 👋 Do you need help on writing your prompt?
          </div>
          <Button
            className="icon-btn w-7 h-7 p-4 flex-center bg-bg rounded-[10px] border border-border"
            onClick={() => {
              wrapHandleClose();
              dispatch(setIsPromptAssistantFullyClosed(true));
            }}
          >
            <IconClose className="flex-shrink-0 text-white w-2.5 h-2.5" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default PromptAssistant;
