import userService from "api/user/user.service";
import Button from "components/Button/Button";
import Modal from "components/Modal/Modal";
import React from "react";
import { useDispatch } from "react-redux";
import { EventDispatchLogout } from "router/Router";
import { useAppSelector } from "store";
import { toggleDeleteAccountModal } from "store/commonSlice";

const DeleteAccountModal = () => {
  const dispatch = useDispatch();
  const { showDeleteAccountModal, user } = useAppSelector((state) => state.common);

  function onClose() {
    dispatch(toggleDeleteAccountModal());
  }
  function onDelete() {
    userService.deleteAccount(user.id).then(() => {
      dispatch(toggleDeleteAccountModal());
      window.dispatchEvent(new CustomEvent(EventDispatchLogout));
    });
  }

  return (
    <Modal onClose={onClose} show={showDeleteAccountModal} bodyClassName="w-[29%]">
      <div className="flex flex-col w-full items-center p-5">
        <div className="flex flex-col items-center gap-2.5 px-2.5 pb-5">
          <h3 className="text-h3 text-white">Delete Account</h3>
          <h6 className="text-bodyMd text-center text-grey max-w-[340px]">
            Are you sure you want to permanently delete your account? <span className="text-h6 text-orange">This action cannot be undone.</span>
          </h6>
        </div>
        <div className="flex flex-col w-full gap-2.5">
          <Button className="btn-warning" onClick={onDelete}>
            Delete Account
          </Button>
          <Button className="btn-secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;
