import { MintgramURL } from "./index";
import { ApiResponse } from "./HttpClient";
import { IHomePageImagesResponse, LandingTripleResponse } from "./home.type";

export default {
  async getHomePageImages(params: any): Promise<ApiResponse<IHomePageImagesResponse>> {
    return await MintgramURL.get("v1/action_list_main", { params });
  },
  async getStyleList(): Promise<ApiResponse<IHomePageImagesResponse>> {
    return await MintgramURL.get("v1/style_list");
  },
  async getImageDetails(params: any): Promise<any> {
    return await MintgramURL.get("v1/action_list_detail", { params });
  },
  async getSubscriptionInfo(): Promise<ApiResponse<any>> {
    return await MintgramURL.get("v1/subscribe_info", {});
  },
  async search(data: any): Promise<ApiResponse<any>> {
    return await MintgramURL.post("v1/search", data, {});
  },
  async getPostDetail(uuid: string): Promise<ApiResponse<any>> {
    return await MintgramURL.get(`v1/post_detail/${uuid}`, {});
  },
  async getHomeSectionImages(): Promise<ApiResponse<LandingTripleResponse>> {
    return await MintgramURL.get(`v1/landing_page`, {});
  },
  async getShopTheLookItems(img_path: string): Promise<ApiResponse<any>> {
    return await MintgramURL.post(`v1/shop_to_look`, { img_path: img_path }, {});
  },
};
