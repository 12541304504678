import React, { useEffect, useRef } from "react";
import { useCreate } from "../CreateContext";
import Select from "components/Select/Select";
import { AnalyticsEvent } from "utils";
import { IconBucket } from "icons";

export enum GuidanceValue {
  Weak = "Weak",
  Default = "Default",
  Strong = "Strong",
}

const AdvancedSettings = () => {
  const { styleOptions, onSetStylePrompt, formValues } = useCreate();
  const [styleValue, setStyleValue] = React.useState(formValues.style_prompt);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (styleOptions.length !== 0) setStyleValue(styleOptions[1]);
  }, [styleOptions]);

  useEffect(() => {
    setStyleValue(formValues.style_prompt);
  }, [formValues]);

  return (
    <>
      <div ref={menuRef} className=" flex flex-col w-full top-full border border-white border-opacity-10 rounded-lg p-3">
        <div className="flex flex-col gap-2.5">
          <h6 className="text-h6 text-white flex gap-2.5">
            <IconBucket /> Model Type
          </h6>
          <Select
            options={styleOptions}
            value={styleValue}
            onChange={(val) => {
              AnalyticsEvent("create_model_type_changed", "", "click");
              setStyleValue(val);
              onSetStylePrompt(val);
            }}
          ></Select>
        </div>
      </div>
    </>
  );
};

export default AdvancedSettings;
