import React from "react";
import Button from "components/Button";
import { IconGoogle } from "icons";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import userService from "api/user/user.service";
import useAuthToken from "hooks/useAuthToken";
import { v4 as uuidv4 } from "uuid";
import { AnalyticsEvent } from "utils";
import { useLocalStorage } from "hooks/useLocalStorage";
import { CLIENT_ID_LOCAL_STORAGE_KEY } from "utils/constants";
import { useDispatch } from "react-redux";
import { setIsAuthorized, setUser } from "store/commonSlice";
import { MVPrefabricURL, MintgramURL } from "api";

const GoogleButton = ({ text, afterComplete }: any) => {
  const dispatch = useDispatch();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      axios
        .get("https://www.googleapis.com/oauth2/v2/userinfo", {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
          },
        })
        .then((response) => {
          const client_id = uuidv4();
          const { email, id } = response.data;
          userService.googleLogin({ email: email, google_token: id, client_id: client_id }).then((res: any) => {
            if (res.return_text === "User created") AnalyticsEvent("google_sign_up", "", "click");
            else if (res.return_text === "Login success") AnalyticsEvent("google_login", "", "click");

            useAuthToken.setAuthTokenFromLocalStorage(res.token);
            dispatch(setUser({ id: res.id }));
            dispatch(setIsAuthorized(true));
            useLocalStorage().setItem(CLIENT_ID_LOCAL_STORAGE_KEY, client_id);
            MVPrefabricURL.setAuthToken(res.token);
            MintgramURL.setAuthToken(res.token);
            // if (res.return_text === "User created") window.location.href = PATHS.WELCOME;

            afterComplete();
          });
        })
        .catch((error) => {
          console.error("Error retrieving user information:", error);
        });
    },
    onError: (error) => console.log("Login Failed:", error),
    prompt: "consent",
  });

  return (
    <Button className="btn-google text-h6 text-bg border border-grey-light" onClick={() => login()}>
      <IconGoogle className="w-5 h-5" />
      {text}
    </Button>
  );
};

export default GoogleButton;
